import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Paragraph, { Display } from '../components/typography';

const NotFound = () => (
  <Layout>
    <SEO
      title="404 Page Not Found"
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
        {
          name: 'googlebot',
          content: 'noindex',
        },
      ]}
    />
    <section
      className="max-w-full h-full mt-2 lg:mt-8"
      style={{
        backgroundImage: `url("/svg/circle-480.svg")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%',
        backgroundSize: 'contain',
      }}
    >
      <Display as="h2" level="1" className="block w-full text-center">
        404
      </Display>
      <div className="lg:flex lg:flex-row lg:justify-center w-full">
        <Paragraph className="my-2 lg:my-8 text-center lg:text-left" level="2">
          We are re-branding and updating our website content.
          <br />
          Try contacting us or going onto our social media for more information.
          <br />
          <br />
          <Link to="/" className="text-secondary">
            Go home
          </Link>
          .
        </Paragraph>
      </div>
    </section>
  </Layout>
);
export default NotFound;
